import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import TextBlock from "../components/text-block"

function CheatsheetsPage({ location, data }) {
  const cheatsheets = data.allCheatsheetsYaml.nodes
  const images = data.allFile.edges.map(image => image.node)

  const textBlockContent = { content: [
    {
      key: "h2",
      value: "Docker, JVM Internals, and Java 8: A Closer Look at Key Concepts",
    },
    {
      key: "h3",
      value: "What is it?",
    },
    {
      key: "h4",
      value: "The ability to write more efficient and optimized code",
    },
    {
      key: "p",
      value:
        "In the world of programming, understanding key concepts and technologies is essential for developers. It helps to enhance their skills and create efficient software solutions. In this post, we will explore the fascinating realms of Docker, JVM Internals, and Java 8. We will cover the basics of Docker and its key components like Docker run and Docker file, dive into the intricate workings of JVM Internals and the significance of the class file. Also we will touch upon the powerful features of Java 8, including the Steam API.",
    },
    {
      key: "h3",
      value: "Docker: Simplifying Application Deployment",
    },
    {
      key: "p",
      value:
        'Docker has revolutionised the way we deploy applications by introducing lightweight, isolated containers. Docker run is a fundamental command that allows us to create and run containers. It provides an easy and consistent environment for our applications. With Docker files, we can define the configuration and dependencies required for our application, enabling reproducibility and portability across different systems. Docker eliminates the dreaded "it works on my machine" issue. Ensuring consistent deployment and minimising compatibility concerns.',
    },
    {
      key: "h3",
      value: "JVM Internals: Unveiling the Magic",
    },
    {
      key: "p",
      value:
        "The Java Virtual Machine (JVM) is the heart of Java, responsible for executing Java bytecode. Understanding JVM Internals can empower developers to write optimised and efficient code. The class file serves as the foundation of JVM Internals, containing the bytecode and metadata that the JVM interprets and runs. By delving into JVM Internals, developers can gain insights into memory management, garbage collection, and the execution of bytecode, ultimately optimising execution and improving performance.",
    },
    {
      key: "h3",
      value: "Java 8: Unleashing the Power of Streams",
    },
    {
      key: "p",
      value:
        "Java 8 introduced several groundbreaking features, but perhaps none more powerful than the Stream API. The Stream API integrates functional programming with Java, providing elegant ways to process collections and perform operations in parallel. With the Stream API, developers can manipulate and transform data effortlessly, simplifying complex problems and improving code readability. Additionally, Java 8's lambda expressions enable concise and expressive coding, allowing developers to write functional-style code more seamlessly.",
    },
    {
      key: "h3",
      value: "Conclusion:",
    },
    {
      key: "p",
      value:
        "In this post, we explored the key concepts of Docker, JVM Internals, and Java 8. Docker offers a seamless approach to deploying applications with its containerisation technology, bolstered by commands like Docker run and Docker file. Understanding JVM Internals empowers developers to write optimised Java code. It leverages the class file and diving into memory management and bytecode execution. Lastly, Java 8's powerful features, including the Stream API and lambda expressions, enhance productivity and enable more elegant and expressive coding.",
    },
    {
      key: "p",
      value:
        "By familiarizing ourselves with these concepts, we can unlock new possibilities in software development, streamline processes, and create more robust and efficient solutions. Whether it's building containerized applications, optimizing Java code, or leveraging Java 8's advanced features, embracing these technologies will undoubtedly elevate our programming skills and enable us to tackle complex challenges effectively.",
    },
  ]}

  return (
    <Layout location={location}>
      <Seo
        title="Quick References for Developers and DevOps Engineers"
        metaKeywords={[
          "cheatsheets",
          "cheat sheets",
          "devops",
          "java cheat sheet",
          "java",
          "println",
          "data types",
          "hello world",
          "core java cheat sheet",
          "line comment",
          "cheatsheet java",
          "java cheat sheet for beginners",
          "programming language",
        ]}
        metaDescription="The Cheatsheets is a quick reference guide for Developers and DevOps Engineers to help them understand the internal workings of the tools and languages"
      />

      <section className="mt-8 mb-20 lg:mt-18">
        <header>
          <h1>Cheatsheets</h1>
          <p className="ml-2 mt-2">
            Just because they are called cheatsheets doesn't mean it is
            cheating.
          </p>
        </header>

        <div className="mt-11 flex flex-wrap gap-5 md:mt-14 lg:gap-6">
          {cheatsheets.map((cheatsheet, cheatsheetIndex) => {
            return (
              <div
                key={cheatsheetIndex}
                className="group w-full md:w-1/2 lg:w-1/3"
              >
                <Link to={"/cheatsheets/" + cheatsheet.slug + "/"}>
                  <div className="group-hover:bg-gradient-to-t group-hover:from-rose-500">
                    <img
                      src={
                        findImage(images, cheatsheet.filePrefix, "@thumb.png")
                          ?.publicURL
                      }
                      className="w-full group-hover:opacity-70"
                    />
                  </div>
                  <h3>{cheatsheet.title}</h3>
                </Link>
              </div>
            )
          })}
        </div>
        <TextBlock contentList={textBlockContent}/>
      </section>
    </Layout>
  )
}

function findImage(images, prefix, suffix) {
  return images.find(
    image => image.base.startsWith(prefix) && image.base.endsWith(suffix)
  )
}

export default CheatsheetsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allFile(
      filter: {
        sourceInstanceName: { eq: "cheatsheets" }
        relativeDirectory: { eq: "images" }
      }
    ) {
      edges {
        node {
          id
          base
          extension
          publicURL
        }
      }
    }
    allCheatsheetsYaml {
      nodes {
        id
        slug
        filePrefix
        title
      }
    }
  }
`
