import React from "react"
import PropTypes from "prop-types";

function TextBlock({contentList}) {
  return <div className="cheatsheet-content mt-10 bg-gray-100">
    <div className="container mx-auto px-4 py-8">
      {contentList.content.map((section) => renderContent(section))}
    </div>
  </div>
}
const renderContent = (section) => {
  console.log('section ----', section);
  switch (section.key) {
    case "h1":
      return <h1 key={Math.random()} className="text-2xl font-bold mb-4">{section.value}</h1>;
    case "p":
      return <p key={Math.random()} className="mb-2 text-sm">{section.value}</p>;
    case "h2":
      return <h2 key={Math.random()} className="text-lg font-bold mb-4">{section.value}</h2>;
    case "h3":
      return <h3 key={Math.random()} className="text-md font-bold mb-4">{section.value}</h3>;
    case "h4":
      return <h4 key={Math.random()} className="text-md opacity-80 font-bold mb-4">{section.value}</h4>;
    case "ul":
      return (
        <ul key={Math.random()} className="list-disc list-inside mb-4">
          {section.values?.map((item, itemIndex) => (
            <li
              key={itemIndex}
              className="text-sm mb-2"
              dangerouslySetInnerHTML={renderHTML(item)}
            ></li>
          ))}
        </ul>
      );
    default:
      return null;
  }
};

const renderHTML = (html) => {
  return { __html: html };
};

TextBlock.propTypes = {
  contentList: PropTypes.object,
}
export default TextBlock;